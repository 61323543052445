import { Link } from 'react-router-dom';

function Aside({ title, menuItems }) {

    return (
        <aside className="block shadow">
            <div className="aside-header">
                <h5>{title} Options</h5>
            </div>

            <div className="aside-body mt-4">
                <ul className="m-0 p-0 list-unstyled">
                    {menuItems.map((item, index) => (
                        <li key={index} className="my-1">
                            <Link className={item.active ? 'active' : ''} to={item.path}>{item.title}</Link>
                        </li>
                    ))}
                </ul>
            </div>
        </aside>
    );
}

export default Aside;