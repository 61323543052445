import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useEvent } from '../../utils/hooks/EventProvider';
import { Form, Button, Modal, Image, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimes, faSpinner, faImage } from '@fortawesome/free-solid-svg-icons';
import { ResetMessages, SetFormErrors } from '../../utils/misc/formControl';
import { Api } from '../../utils/services/Api';
import { selectStyles } from '../../utils/misc/selectStyles';
import FormMessage from '../global/FormMessage';
import BlockOverlay from '../global/BlockOverlay';
import Select from 'react-select';

function EventPartner() {

    const { t } = useTranslation();
    const { loading, event, getEvent } = useEvent();

    const [modalShow, setModalShow] = useState(false);
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({
        partner: null,
    });
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [partners, setPartners] = useState([]);
    const [partnerOptions, setPartnerOptions] = useState([]);

    const getPartners = async () => {
        try {
            const res = await Api.get('/partners');
            setPartners(res.data.data.partners);
        } catch (err) {
            // Silence is golden.
        }
    }

    useEffect(() => {
        getPartners();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    useEffect(() => {
        if (partners && partners.length > 0) {
            const options = [];
            partners.forEach((partner) => options.push({ value: partner.id, label: partner.name }));
            setPartnerOptions(options);
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partners]);

    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
    );

    const handleSubmit = async (e) => {
        e.preventDefault();

        setWorking(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, null, true);

        try {
            await Api.put(`/events/${event.id}/partner`, data);
            setData({
                partner: null,
            });
            setModalShow(false);
            getEvent();
        } catch (err) {
            SetFormErrors(err, errors, setErrors, setErrorMessage);
        } finally {
            setWorking(false);
        }
    }

    const handleDelete = async (id) => {
        try {
            await Api.delete(`/events/${id}/partner`);
            getEvent();
        } catch (err) {
            // Silence is golden.
        }
    }

    return (
        <>
            {loading ? <BlockOverlay /> :
                <div>
                    <h3>{t('event')} - {event.name}</h3>

                    {!event.partner &&
                        <Button variant="primary" className="me-3" onClick={() => setModalShow(true)}>
                            {t('attachPartner')}
                        </Button>
                    }

                    <Modal show={modalShow}>
                        <div className="p-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="m-0">{t('attachPartner')}</h6>
                                <Button className="icon-btn" onClick={() => setModalShow(false)}>
                                    <FontAwesomeIcon icon={faTimes} size="lg" className="text-dark" />
                                </Button>
                            </div>

                            <div className="mt-4">
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-2" controlId="partner">
                                        <Form.Label>{t('partner')}</Form.Label>
                                        <Select
                                            options={partnerOptions}
                                            styles={selectStyles}
                                            value={partnerOptions.find((option) => option.value === data.artist)}
                                            onChange={(option) => setData({ ...data, partner: option.value })}
                                            className={`react-select ${errors.partner ? 'is-invalid' : ''}`}
                                            placeholder={t('selectDots')}
                                            isDisabled={working}
                                        />
                                        {errors.partner && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.partner}</Form.Control.Feedback>}
                                    </Form.Group>

                                    <Button type="submit" variant="primary" className="mt-3" {...working ? {disabled: true} : {} }>
                                        {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                        {t('submit')}
                                    </Button>

                                    {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                                </Form>
                            </div>
                        </div>
                    </Modal>

                    <Row className="mt-5">
                        {event.partner ?
                            <Col xs={12} md={6} lg={4}>
                                <div className="border rounded-4 p-3 mb-2 h-100 d-flex flex-column justify-content-between list-item">
                                    <Link to={`/partners/${event.partner.id}`} className="d-inline-block w-100 h-100">
                                        <div className="text-center">
                                            {event.partner.image ? <Image src={event.partner.image} className="partner-image" /> : <FontAwesomeIcon icon={faImage} className="text-secondary partner-image" />}
                                            <div>
                                                <h6 className="mb-0 mt-2">{event.partner.name}</h6>
                                            </div>
                                        </div>
                                    </Link>

                                    <Button variant="danger" className="btn-sm rounded mt-2" onClick={() => handleDelete(event.id)}>
                                        {t('detach')}
                                    </Button>
                                </div>
                            </Col>
                        :
                            <div className="rounded-4 px-3 py-4 mt-4 info-box">
                                <p className="m-0">
                                    <FontAwesomeIcon icon={faInfoCircle} className="text-primary me-3" size="lg" />
                                    {t('noPartnerFound')}
                                </p>
                            </div> 
                        }
                    </Row>
                </div>
            }
        </>
    );
}

export default EventPartner;