import { useTranslation } from 'react-i18next';
import { Button, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../utils/hooks/AuthProvider';

function ProfileQR() {

    const { t } = useTranslation();
    const { user } = useAuth();

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = user.qr_code;
        link.download = 'qr_code.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            <h3>{t('qrCode')}</h3>

            <div className="mt-4 text-center text-md-start">
                <Image src={user.qr_code} fluid />
            </div>

            <div className="rounded-4 px-3 py-4 mt-4 info-box">
                <p className="m-0">
                    <FontAwesomeIcon icon={faInfoCircle} className="text-primary me-3" size="lg" />
                    {t('qrCodeInfo')}
                </p>

                <Button variant="primary" className="text-light mt-3" onClick={handleDownload}>
                    <FontAwesomeIcon icon={faDownload} className="me-2" />
                    {t('download')}
                </Button>
            </div>
        </div>         
    );
}

export default ProfileQR;