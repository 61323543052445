import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useEvent } from '../../utils/hooks/EventProvider';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ResetMessages, SetFormErrors } from '../../utils/misc/formControl';
import { Api } from '../../utils/services/Api';
import { parseDateTime } from '../../utils/misc/formatDate';
import BlockOverlay from '../global/BlockOverlay';
import FormMessage from '../global/FormMessage';
import DatePicker from 'react-datepicker';

function EventEditInfoForm() {

    const { t } = useTranslation();
    const { loading, event, getEvent } = useEvent();
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({});
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    useEffect(() => {
        setData({
            name: event.name,
            description: event.description,
            start: event.start,
            end: event.end,
            price: event.price,
            discounted_price: event.discounted_price,
        });

        setPrevData(data);
    }
    // eslint-disable-next-line
    , [event]);

    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
    );

    const handleSubmit = async (e) => {
        e.preventDefault();

        setWorking(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, setSuccessMessage, true);

        try {
            const res = await Api.put(`/events/${event.id}`, data);
            setSuccessMessage(res.data.message);
            getEvent();
        } catch (err) {
            SetFormErrors(err, errors, setErrors, setErrorMessage);
        } finally {
            setWorking(false);
        }
    }

    return (
        <>
            {loading || Object.keys(data).length === 0 ?
                <BlockOverlay />
            :
                <Form className="mt-5" onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-2" controlId="name">
                                <Form.Label>{t('name')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('name')}
                                    value={data.name || ''}
                                    onChange={(e) => setData({ ...data, name: e.target.value })}
                                    className={errors.name ? 'is-invalid' : ''}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.name && <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-2" controlId="start">
                                <Form.Label>{t('startDate')}</Form.Label>
                                <DatePicker
                                    selected={data.start ? new Date(data.start) : null}
                                    onChange={(date) => setData({ ...data, start: parseDateTime(date) })}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    showTimeSelect
                                    dropdownMode="select"
                                    placeholderText={t('startDate')}
                                    dateFormat="yyyy-MM-dd hh:mm:ss"
                                    className={errors.start ? 'form-control is-invalid' : 'form-control'}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.start && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.start}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-2" controlId="end">
                                <Form.Label>{t('endDate')}</Form.Label>
                                <DatePicker
                                    selected={data.end ? new Date(data.end) : null}
                                    onChange={(date) => setData({ ...data, end: parseDateTime(date) })}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    showTimeSelect
                                    dropdownMode="select"
                                    placeholderText={t('endDate')}
                                    dateFormat="yyyy-MM-dd hh:mm:ss"
                                    className={errors.end ? 'form-control is-invalid' : 'form-control'}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.end && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.end}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-2" controlId="price">
                                <Form.Label>{t('price')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('price')}
                                    value={data.price || ''}
                                    onChange={(e) => setData({ ...data, price: e.target.value })}
                                    isInvalid={!!errors.price}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.price && <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-2" controlId="discounted_price">
                                <Form.Label>{t('discountedPrice')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('discountedPrice')}
                                    value={data.discounted_price || ''}
                                    onChange={(e) => setData({ ...data, discounted_price: e.target.value })}
                                    isInvalid={!!errors.discounted_price}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.discounted_price && <Form.Control.Feedback type="invalid">{errors.discounted_price}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12}>
                            <Form.Group className="mb-2" controlId="description">
                                <Form.Label>{t('description')}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder={t('description')}
                                    value={data.description || ''}
                                    onChange={(e) => setData({ ...data, description: e.target.value })}
                                    isInvalid={!!errors.description}
                                    {...working ? { disabled: true } : {}}
                                    rows={4}
                                />
                                {errors.description && <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12}>
                            <Button type="submit" variant="primary" className="mt-3" {...working ? {disabled: true} : {} }>
                                {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                {t('update')}
                            </Button>

                            {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                            {successMessage && <FormMessage type="success" message={successMessage} />}
                        </Col>
                    </Row>
                </Form>
            }
        </>
    );
}

export default EventEditInfoForm;