import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { Button } from 'react-bootstrap';
import ReactCountryFlag from 'react-country-flag';

function Langauge() {

    const [cookies, setCookie] = useCookies(); // eslint-disable-line no-unused-vars
    const langauges = {
        en: {
            code: 'en',
            name: 'English',
            flag: 'GB',
        },
        ka: {
            code: 'ka',
            name: 'ქართული',
            flag: 'GE',
        },
    };
    const { i18n } = useTranslation();
    const notActiveLanguage = i18n.language === 'en' ? langauges.ka : langauges.en;

    const changeLanguage = () => {
        i18n.changeLanguage(notActiveLanguage.code);
        setCookie('lang', notActiveLanguage.code);
    }

    return (
        <Button className="icon-btn" onClick={changeLanguage}>
            <ReactCountryFlag
                countryCode={notActiveLanguage.flag}
                svg
                style={{
                    width: '1.3rem',
                    height: '1.3rem',
                    borderRadius: '0.2rem',
                }}
            />
        </Button>
    );
}

export default Langauge;