import { useState, useEffect, createContext, useContext, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Api } from '../services/Api';

const UserContext = createContext();

export const UserProvider = ({ children }) => {

    const navigate  = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});

    const getUser = async () => {
        setLoading(true);

        try {
            const res = await Api.get(`/users/${id}`);
            setUser(res.data.data);
        } catch (err) {
            navigate('/users');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getUser();
    }
    // eslint-disable-next-line
    , []);

    const value = useMemo(
        () => ({
            loading,
            user,
            getUser
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loading, user]
    );

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
}

export const useUser = () => {
    return useContext(UserContext);
}