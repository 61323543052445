import Footer from './Footer';
import Header from './Header';

function Layout({ children }) {
    return (
        <div className="app min-vh-100 d-flex flex-column">
            <Header />
            <div className="content-wrapper">
                {children}
            </div>
            <Footer />
        </div>
    );
}

export default Layout;