import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FormMessage({ type, message }) {
    return (
        <div className="form-message my-3">
            <p className={`m-0 ${type === 'success' ? 'text-primary' : 'text-danger'}`}>
                <FontAwesomeIcon icon={type === 'success' ? faCheckCircle : faTimesCircle} />
                <span className="ms-2">{message}</span>
            </p>
        </div>
    );
}

export default FormMessage;