import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../utils/hooks/UserProvider';
import { useParams } from '../../utils/hooks/ParamsProvider';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ResetMessages, SetFormErrors } from '../../utils/misc/formControl';
import { selectStyles } from '../../utils/misc/selectStyles';
import { Api } from '../../utils/services/Api';
import FormMessage from '../global/FormMessage';
import BlockOverlay from '../global/BlockOverlay';
import Select from 'react-select';

function UserEditRoleStatusForm() {

    const { t } = useTranslation();
    const { loading, user } = useUser();
    const { params } = useParams();
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({});
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const roleOptions = params.user_roles ? params.user_roles : [];
    const statusOptions = params.user_statuses ? params.user_statuses : [];

    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [data]);

    useEffect(() => {
        setData({
            role: user.role,
            status: user.status
        });

        setPrevData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [user]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setWorking(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, null, true);

        try {
            const res = await Api.put(`/users/${user.id}/role-status`, data);
            setSuccessMessage(res.data.message);
        } catch (err) {
            SetFormErrors(err, errors, setErrors, setErrorMessage);
        } finally {
            setWorking(false);
        }
    }

    return (
        <>
            {loading || Object.keys(data).length === 0 ?
                <BlockOverlay />
            :
                <div>
                    <h3>{t('roleStatus')} - {user.first_name} {user.last_name}</h3>

                    <Form onSubmit={handleSubmit} className="mt-4">
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-2" controlId="role">
                                    <Form.Label>{t('role')}</Form.Label>
                                    <Select
                                        value={roleOptions.find((option) => option.value === data.role)}
                                        options={roleOptions}
                                        styles={selectStyles}
                                        placeholder={t('selectDots')}
                                        className={`react-select ${errors.gender ? 'is-invalid' : ''}`}
                                        onChange={(option) => setData({ ...data, role: option.value })}
                                        isDisabled={working}
                                    />
                                    {errors.role && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.role}</Form.Control.Feedback>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} md={6}>
                                <Form.Group className="mb-2" controlId="status">
                                    <Form.Label>{t('status')}</Form.Label>
                                    <Select
                                        value={statusOptions.find((option) => option.value === data.status)}
                                        options={statusOptions}
                                        styles={selectStyles}
                                        placeholder={t('selectDots')}
                                        className={`react-select ${errors.status ? 'is-invalid' : ''}`}
                                        onChange={(option) => setData({ ...data, status: option.value })}
                                        isDisabled={working}
                                    />
                                    {errors.status && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.status}</Form.Control.Feedback>}
                                </Form.Group>
                            </Col>

                            <Col xs={12}>
                                <Button type="submit" variant="primary" className="mt-3" {...working ? {disabled: true} : {} }>
                                    {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                    {t('update')}
                                </Button>

                                {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                                {successMessage && <FormMessage type="success" message={successMessage} />}
                            </Col>
                        </Row>
                    </Form>
                </div>
            }
        </>
    );
}

export default UserEditRoleStatusForm