import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';

function LanguageForm({ offLang }) {

    const { i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(); // eslint-disable-line no-unused-vars

    const changeLanguage = (e) => {
        setCookie('lang', e.target.value);
    }

    useEffect(() => {
        i18n.changeLanguage(cookies.lang);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cookies]);

    return (
        <>
            {!offLang &&
                <Form className="mt-5">
                    <Form.Group className="mb-3" controlId="language">
                        <Form.Select
                            size="sm"
                            className="w-auto"
                            value={i18n.language}
                            onChange={changeLanguage}
                        >
                            <option value="en">English</option>
                            <option value="ka">ქართული</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
            }
        </>
    )
}

export default LanguageForm;