import { useState, useEffect } from 'react';
import { AuthProvider } from './AuthProvider';
import { ParamsProvider } from './ParamsProvider';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

function AppProvider({ children }) {

    const { i18n } = useTranslation();
    const [cookies] = useCookies();
    const [langSet, setLangSet] = useState(false);
    const languages = ['en', 'ka'];

    useEffect(() => {
        if (cookies.lang) {
            if (languages.includes(cookies.lang)) {
                i18n.changeLanguage(cookies.lang);
            }
        } else {
            i18n.changeLanguage('en');
        }
        setLangSet(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
            
    return(
        <>
            {langSet &&
                <AuthProvider>
                    <ParamsProvider>
                        { children }
                    </ParamsProvider>
                </AuthProvider>
            }
        </>
    );
}

export default AppProvider;