import { useTranslation } from 'react-i18next';
import { useUser } from '../../utils/hooks/UserProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import BlockOverlay from '../global/BlockOverlay';
import UserSingleList from './UserSingleList';

function UserLogs() {

    const { t } = useTranslation();
    const { loading, user } = useUser();

    return (
        <>
            {loading ? <BlockOverlay /> :
                <div>
                    <h3>{t('logs')} - {user.first_name} {user.last_name}</h3>

                    <div className="mt-5">
                        {user.logs && user.logs.length > 0 ?
                            <>
                                {user.logs.map((item, index) => (
                                    <div key={index} className="log-item">
                                        <h6>{item.created_at}: {item.action}</h6>
                                        <p>{item.description}</p>
                                    </div>
                                ))}
                            </>
                        :
                            <div className="rounded-4 px-3 py-4 mt-4 info-box">
                                <p className="m-0">
                                    <FontAwesomeIcon icon={faInfoCircle} className="text-primary me-3" size="lg" />
                                    {t('noLogsFound')}
                                </p>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default UserLogs;