import { Col, Row } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';


function EventActivitySingleUser({ item }) {

    const image = item.user && item.user.avatar ? <Image src={item.user.avatar} className="header-avatar" /> : <FontAwesomeIcon icon={faUserCircle} className="text-secondary header-avatar" />;
    const type = item.even_list ? item.event_list.type_name : item.user ? item.user.status_name : '';

    return (
        <Row className="py-2 border-bottom">
            <Col xs={12} md={1} className="d-flex align-items-center justify-content-start justify-content-md-center">
                {image}
            </Col>

            <Col xs={12} md={3} className="d-flex align-items-center">
                {item.user && item.user.first_name} {item.user && item.user.last_name}
            </Col>

            <Col xs={12} md={1} className="d-flex align-items-center">
                {item.user && item.user.gender_name}
            </Col>

            <Col xs={12} md={1} className="d-flex align-items-center">
                {item.user && item.user.age && item.user.age}
            </Col>

            <Col xs={12} md={2} className="d-flex align-items-center">
                {item.event_list && item.event_list.name}
            </Col>

            <Col xs={12} md={1} className="d-flex align-items-center">
                {type}
            </Col>

            <Col xs={12} md={1} className="d-flex align-items-center">
                {item.paid} ₾
            </Col>
        </Row>
    );
}

export default EventActivitySingleUser;