import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from '../../utils/services/Api';
import Pagination from '../../components/global/Pagination';
import ArtistCreate from '../../components/artist/ArtistCreate';
import ArtistsFilterForm from '../../components/artist/ArtistsFilterForm';
import ArtistsList from '../../components/artist/ArtistsList';
import ListLayout from '../../components/global/ListLayout';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function ArtistsListPage() {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({});
    const [artists, setArtists] = useState([]);

    const setFilterData = (data) => {
        setData(data);
    }

    const getArtists = async () => {
        setLoading(true);
        try {
            const res = await Api.get('/artists', {
                params: {
                    page: page,
                    ...data
                }
            });
            setArtists(res.data.data.artists);
            setPagination(res.data.data.pagination);
        } catch (err) {
            setArtists([]);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        setPage(1);
        getArtists();
    },
    // eslint-disable-next-line
    [data]);

    useEffect(() => {
        getArtists();
    }
    // eslint-disable-next-line
    , [page]);

    return (
        <ListLayout>
            <div className="d-flex align-items-center mb-4">
                <Link to="/"><FontAwesomeIcon icon={faArrowLeft} className="me-3" size="lg" /></Link>
                <h1 className="mb-0 me-3">{t('artists')}</h1>
                <ArtistCreate callback={getArtists} />
            </div>

            <div className="block shadow position-relative">
                <ArtistsFilterForm callback={setFilterData} />
                <ArtistsList loading={loading} artists={artists} getArtists={getArtists} />
                <Pagination page={page} lastPage={pagination.total_pages} setPage={setPage} />
            </div>
        </ListLayout>
    );
}

export default ArtistsListPage;