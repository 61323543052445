import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EventProvider } from '../../utils/hooks/EventProvider';
import { useAuth } from '../../utils/hooks/AuthProvider';
import { eventMenuItems } from '../../utils/misc/eventMenuItems';
import SingleLayout from '../../components/global/SingleLayout';
import EventDelete from '../../components/event/EventDelete';

function EventDeletePage() {

    const { id } = useParams();
    const { t } = useTranslation();
    const { user } = useAuth();
    const menu = eventMenuItems(user, '/events/delete', id);

    return (
        <SingleLayout title={t('event')} menuItems={menu} back="/events">
            <div className="block shadow position-relative">
                <EventProvider>
                    <EventDelete />
                </EventProvider>
            </div>
        </SingleLayout>
    );
}

export default EventDeletePage;