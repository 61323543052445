import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function BlockOverlay() {
    return (
        <div className="block-overlay">
            <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-primary" />                    
        </div>
    );
}

export default BlockOverlay