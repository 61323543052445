import { useState, useEffect, createContext, useContext, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Api } from '../services/Api';

const EventListContext = createContext();

export const EventListProvider = ({ children }) => {

    const navigate  = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [eventList, setEventList] = useState({});

    const getEventList = async () => {
        setLoading(true);

        try {
            const res = await Api.get(`/event-lists/${id}/list`);
            setEventList(res.data.data);
        } catch (err) {
            navigate('/error');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getEventList();
    }
    // eslint-disable-next-line
    , []);

    const value = useMemo(
        () => ({
            loading,
            eventList,
            getEventList
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loading, eventList]
    );

    return (
        <EventListContext.Provider value={value}>
            {children}
        </EventListContext.Provider>
    );
}

export const useEventList = () => {
    return useContext(EventListContext);
}