import i18n from '../../i18n';

export const artistMenuItems = (active, id) => {

    let items = [
        {
            title: i18n.t('overview'),
            path: '/artists/' + id,
            active: false
        },
        {
            title: i18n.t('artistInfo'),
            path: '/artists/info/' + id,
            active: false
        },
        {
            title: i18n.t('gallery'),
            path: '/artists/gallery/' + id,
            active: false
        },
        {
            title: i18n.t('user'),
            path: '/artists/user/' + id,
            active: false
        },
        {
            title: i18n.t('delete'),
            path: '/artists/delete/' + id,
            active: false
        }
    ];

    items.forEach((item) => {
        if (active + '/' + id === item.path) {
            item.active = true;
        }
    });

    return items;
}