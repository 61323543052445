import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { parseDate } from '../../utils/misc/formatDate';
import DatePicker from 'react-datepicker';

function EventsFilterForm({ callback }) {
    
    const { t } = useTranslation();
    const [data, setData] = useState({
        name: '',
        start: '',
        end: '',
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        callback(data);
    }

    const resetFilter = () => {
        setData({
            name: '',
            start: '',
            end: '',
        });
        callback({});
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col xs={12} md={4} lg={3} xxl={2} className="mb-2">
                    <Form.Group controlId="name">
                        <Form.Control
                            type="text"
                            placeholder={t('name')}
                            value={data.name}
                            onChange={(e) => setData({ ...data, name: e.target.value })}
                        />
                    </Form.Group>
                </Col>

                <Col xs={6} md={4} lg={3} xxl={2} className="mb-2">
                    <DatePicker
                        selected={data.start ? new Date(data.start) : null}
                        onChange={(date) => setData({ ...data, start: date ? parseDate(date) : null })}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        placeholderText={t('startDate')}
                    />
                </Col>

                <Col xs={6} md={4} lg={3} xxl={2} className="mb-2">
                    <DatePicker
                        selected={data.end ? new Date(data.end) : null}
                        onChange={(date) => setData({ ...data, end: date ? parseDate(date) : null })}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        placeholderText={t('endDate')}
                    />
                </Col>

                <Col xs={12} md={4} lg={3} xxl={3} className="mb-2">
                    <Button type="submit" variant="primary">
                        {t('filter')}
                    </Button>

                    <Button type="button" variant='link' className="ms-3 rounded" size="sm" onClick={resetFilter}>
                        <FontAwesomeIcon icon={faTimes} className="me-2" />
                        {t('reset')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

export default EventsFilterForm;