import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useEventList } from '../../utils/hooks/EventListProvider';
import { useParams } from '../../utils/hooks/ParamsProvider';
import { Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { SetFormErrors, ResetMessages } from '../../utils/misc/formControl';
import { Api } from '../../utils/services/Api';
import { selectStyles } from '../../utils/misc/selectStyles';
import FormMessage from '../global/FormMessage';
import Select from 'react-select';

function EventListInviteBulk() {

    const { t } = useTranslation();
    const { loading, eventList, getEventList } = useEventList();
    const { params } = useParams();
    const [modalShow, setModalShow] = useState(false);
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({
        list: '',
    });
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');

    const listOptions = params.lists || [];

    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]);
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        setWorking(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, null, true);

        try {
            await Api.post(`/event-lists/${eventList.id}/add-users-list/${data.list}`);
            getEventList();
            setModalShow(false);
            setData({
                list: '',
            });
        } catch (err) {
            SetFormErrors(err, errors, setErrors, setErrorMessage);
        } finally {
            setWorking(false);
        }
    }

    return (
        <>
            {!loading &&
                <>
                    {eventList.max_invitations !== eventList.invitations.length &&
                        <Button variant="primary" onClick={() => setModalShow(true)} className="ms-2">
                            {t('bulkInvite')}
                        </Button>
                    }

                    <Modal show={modalShow}>
                        <div className="p-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="m-0">{t('invite')}</h6>
                                <Button className="icon-btn" onClick={() => setModalShow(false)}>
                                    <FontAwesomeIcon icon={faTimes} size="lg" className="text-dark" />
                                </Button>
                            </div>

                            <div className="mt-4">
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-2" controlId="list">
                                        <Form.Label>{t('list')}</Form.Label>
                                        <Select
                                            value={listOptions.find((option) => option.value === data.role)}
                                            options={listOptions}
                                            styles={selectStyles}
                                            placeholder={t('selectDots')}
                                            className={`react-select ${errors.gender ? 'is-invalid' : ''}`}
                                            onChange={(option) => setData({ ...data, list: option.value })}
                                            isDisabled={working}
                                        />
                                        {errors.list && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.list}</Form.Control.Feedback>}
                                    </Form.Group>

                                    <Button type="submit" variant="primary" className="mt-3" {...working ? {disabled: true} : {} }>
                                        {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                        {t('submit')}
                                    </Button>

                                    {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                                </Form>
                            </div>
                        </div>
                    </Modal>
                </>
            }
        </>  
    );
}

export default EventListInviteBulk;