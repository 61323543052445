import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePartner } from '../../utils/hooks/PartnerProvider';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ResetMessages, SetFormErrors } from '../../utils/misc/formControl';
import { Api } from '../../utils/services/Api';
import BlockOverlay from '../global/BlockOverlay';
import FormMessage from '../global/FormMessage';

function PartnerEditInfoForm() {

    const { t } = useTranslation();
    const { loading, partner, getPartner } = usePartner();
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({});
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    useEffect(() => {
        setData({
            name: partner.name,
            description: partner.description,
        });

        setPrevData(data);
    }
    // eslint-disable-next-line
    , [partner]);

    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
    );

    const handleSubmit = async (e) => {
        e.preventDefault();

        setWorking(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, setSuccessMessage, true);

        try {
            const res = await Api.put(`/partners/${partner.id}`, data);
            setSuccessMessage(res.data.message);
            getPartner();
        } catch (err) {
            SetFormErrors(err, errors, setErrors, setErrorMessage);
        } finally {
            setWorking(false);
        }
    }

    return (
        <>
            {loading || Object.keys(data).length === 0 ?
                <BlockOverlay />
            :
                <Form className="mt-5" onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12} md={12}>
                            <Form.Group className="mb-2" controlId="name">
                                <Form.Label>{t('name')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('name')}
                                    value={data.name || ''}
                                    onChange={(e) => setData({ ...data, name: e.target.value })}
                                    isInvalid={!!errors.name}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.name && <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12}>
                            <Form.Group className="mb-2" controlId="description">
                                <Form.Label>{t('description')}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder={t('description')}
                                    value={data.description || ''}
                                    onChange={(e) => setData({ ...data, description: e.target.value })}
                                    isInvalid={!!errors.description}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.description && <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12}>
                            <Button type="submit" variant="primary" className="mt-3" {...working ? {disabled: true} : {} }>
                                {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                {t('update')}
                            </Button>

                            {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                            {successMessage && <FormMessage type="success" message={successMessage} />}
                        </Col>
                    </Row>
                </Form>
            }
        </>
    );
}

export default PartnerEditInfoForm;