import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Row, Col, Image, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faPhone, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import BlockOverlay from '../global/BlockOverlay';

function ListUsersList({ loading, users }) {

    const { t } = useTranslation();

    return (
        <>
            {loading ? 
                <BlockOverlay />
            :
                <>
                    {users.map((user, index) => (
                        <div key={index} className="border rounded-4 p-3 mb-2 list-item">
                            <Row>
                                <Col xs={12} md={4} className="d-flex align-items-center py-1">
                                    {user.avatar ? <Image src={user.avatar} className="header-avatar" /> : <FontAwesomeIcon icon={faUserCircle} className="text-secondary header-avatar" />}
                                    <div className="ms-3">
                                        <p className="fw-bold m-0">
                                            {user.first_name} {user.last_name}
                                        </p>

                                        <p className="text-muted m-0" style={ {fontSize: '0.75rem'} }>
                                            <a href={`mailto:${user.email}`} className="muted">{user.email}</a>
                                        </p>
                                    </div>
                                </Col>

                                <Col xs={12} md={3} className="d-flex align-items-center py-1">
                                    <div>
                                        <p className="fw-bold m-0">
                                            <FontAwesomeIcon icon={faPhone} className="me-2" />
                                            {user.phone_code && user.phone ? `+${user.phone_code} ${user.phone}` : ''}
                                        </p>

                                        <p className="m-0">
                                            <FontAwesomeIcon icon={faIdCard} className="me-2" />
                                            {user.pid}
                                        </p>
                                    </div>
                                </Col>

                                <Col xs={12} md={2} className="d-flex align-items-center py-1">
                                    <div className="m-0">
                                        <Badge bg="dark">{user.role_name}</Badge><br/>
                                        <Badge bg="warning">{user.status_name}</Badge>
                                    </div>
                                </Col>

                                <Col xs={12} md={2} className="d-flex align-items-center py-1">
                                    <Link to={`/users/${user.id}`} className="btn btn-sm btn-secondary rounded">
                                        {t('view')}
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </>    
            }
        </>
    );
}

export default ListUsersList;