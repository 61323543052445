import { AxiosError } from 'axios';
import i18n from '../../i18n';

export const ResetMessages = (data, prevData, setPrevData, errors, setErrors, setErrorMessage, setSuccessMessage = null, force = false) => {

    Object.keys(data).forEach((key) => {
        if (data[key] !== prevData[key]) {
            setErrors({...errors, [key]: null});
        }
    });

    if (force) {
        setErrors({});
    }

    setPrevData(data);
    setErrorMessage('');

    if (setSuccessMessage) {
        setSuccessMessage(null);
    }
}

export const SetFormErrors = (instance, errors, setErrors, setErrorMessage) => {

    if (instance instanceof AxiosError) {

        if (instance.response.data.errors && Object.keys(instance.response.data.errors).length > 0) {
            Object.keys(instance.response.data.errors).forEach((key) => {
                setErrors((errors) => ({ ...errors, [key]: instance.response.data.errors[key][0] }));
            });

            setErrorMessage(i18n.t('checkFields'));
        } else {

            if (instance.response.status > 499) {
                setErrorMessage(i18n.t('somethingWentWrong'));
            } else {
                if (instance.response.data.message) {
                    setErrorMessage(instance.response.data.message);
                } else {
                    setErrorMessage(i18n.t('somethingWentWrong'));
                }
            }
        }
    }
}