import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EventProvider } from '../../utils/hooks/EventProvider';
import { useAuth } from '../../utils/hooks/AuthProvider';
import { eventMenuItems } from '../../utils/misc/eventMenuItems';
import SingleLayout from '../../components/global/SingleLayout';
import EventLists from '../../components/event/EventLists';

function EventListsPage() {

    const { id } = useParams();
    const { t } = useTranslation();
    const { user } = useAuth();
    const menu = eventMenuItems(user, '/events/lists', id);

    return (
        <SingleLayout title={t('event')} menuItems={menu} back="/events">
            <div className="block shadow position-relative">
                <EventProvider>
                    <EventLists />
                </EventProvider>
            </div>
        </SingleLayout>
    );
}

export default EventListsPage;