import { useState, useEffect, createContext, useContext, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Api } from '../services/Api';

const ArtistContext = createContext();

export const ArtistProvider = ({ children }) => {

    const navigate  = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [artist, setArtist] = useState({});

    const getArtist = async () => {
        setLoading(true);

        try {
            const res = await Api.get(`/artists/${id}`);
            setArtist(res.data.data);
        } catch (err) {
            navigate('/artists');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getArtist();
    }
    // eslint-disable-next-line
    , []);

    const value = useMemo(
        () => ({
            loading,
            artist,
            getArtist
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loading, artist]
    );

    return (
        <ArtistContext.Provider value={value}>
            {children}
        </ArtistContext.Provider>
    );
}

export const useArtist = () => {
    return useContext(ArtistContext);
}