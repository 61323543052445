import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Aside from './Aside';
import MobileAsideContent from './MobileAsideContent';

function SingleLayout({ title, menuItems, back, children }) {

    return (
        <Container fluid="xxl">
            <Row>
                <Col xs={12} className="mb-4">
                    <div className="d-flex align-items-center mb-4">
                        {back && <Link to={back}><FontAwesomeIcon icon={faArrowLeft} className="me-3" size="lg" /></Link>}
                        <h1 className="mb-0 me-3">{title}</h1>
                    </div>
                </Col>

                <Col xs={12} xl={3} className="d-none d-xl-block">
                    <Aside title={title} menuItems={menuItems} />
                </Col>

                <Col xs={12} className="d-block d-xl-none">
                    <MobileAsideContent menuItems={menuItems} />
                </Col>

                <Col xs={12} xl={9}>
                    {children}
                </Col>
            </Row>
        </Container>
    );
}

export default SingleLayout;