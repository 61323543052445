import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import LoginForm from '../../components/auth/LoginForm';
import AuthLayout from '../../components/global/AuthLayout';
import Logo from '../../assets/images/logo.png';

function LoginPage() {
    const { t } = useTranslation();
    return (
        <AuthLayout>
            <div className="login-logo">
                <Image src={Logo} fluid />
            </div>

            <div className="mt-4">
                <h4 className="mb-0 fw-bold">{t('login')}</h4>
                <small className="muted">{t('signInToAccount')}</small>
            </div>

            <LoginForm />

            <div className="mt-3">
                <small className="muted">{t('forgotPassword')} <Link to="/reset-password" className="link-primary text-decoration-underline">{t('reset')}</Link></small>
            </div>

            <div className="mt-3">
                <small className="muted">{t('noAccount')} <Link to={process.env.REACT_APP_WEBSITE_URL + '#verify'} className="link-primary text-decoration-underline">{t('register')}</Link></small>
            </div>
        </AuthLayout>
    );
}

export default LoginPage;