import { useTranslation } from 'react-i18next';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../utils/hooks/AuthProvider';
import { formatDate } from '../../utils/misc/formatDate';

function ProfileBlacklists() {

    const { t } = useTranslation();
    const { user } = useAuth();

    return (
        <div>
            <h3>{t('blacklists')}</h3>
            <div className="mt-4">
                {user.blacklists && user.blacklists.length > 0 ?
                    <>
                        {user.blacklists.map((item, index) => (
                            <div key={index} className="border rounded-4 p-3 mb-2">
                                {item.active ? <Badge bg="danger">{t('active')}</Badge> : <Badge bg="dark">{t('notActive')}</Badge>}
                                <p className="mt-3 mb-0">{formatDate(item.start)} - {formatDate(item.start)}</p>
                                <p>{item.reason}</p>
                            </div>
                        ))}
                    </>
                :
                    <div className="rounded-4 px-3 py-4 mt-4 info-box">
                        <p className="m-0">
                            <FontAwesomeIcon icon={faInfoCircle} className="text-primary me-3" size="lg" />
                            {t('noBlacklistsFound')}
                        </p>
                    </div>
                }
            </div>
        </div> 
    );
}

export default ProfileBlacklists;