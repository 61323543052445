import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { userMenuItems } from '../../utils/misc/userMenuItems';
import SingleLayout from '../../components/global/SingleLayout';
import UserProfileOverview from '../../components/user/UserProfileOverview';
import { UserProvider } from '../../utils/hooks/UserProvider';

function UserOverviewPage() {

    const { id } = useParams();
    const { t } = useTranslation();
    const menu = userMenuItems('/users', id);

    return (
        <SingleLayout title={t('user')} menuItems={menu} back="/users">
            <div className="block shadow position-relative">
                <UserProvider>
                    <UserProfileOverview />
                </UserProvider>
            </div>
        </SingleLayout>
    );
}

export default UserOverviewPage;