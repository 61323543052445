import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from '../../utils/services/Api';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ListLayout from '../../components/global/ListLayout';
import PartnersFilterForm from '../../components/partner/PartnersFilterForm';
import PartnersList from '../../components/partner/PartnersList';
import PartnerCreate from '../../components/partner/PartnerCreate';

function PartnerListPage() {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [partners, setPartners] = useState([]);

    const setFilterData = (data) => {
        setData(data);
    }

    const getPartners = async () => {
        setLoading(true);
        try {
            const res = await Api.get('/partners', {
                params: {
                    ...data
                }
            });
            setPartners(res.data.data.partners);
        } catch (err) {
            setPartners([]);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getPartners();
    },
    // eslint-disable-next-line
    [data]);

    return (
        <ListLayout>
            <div className="d-flex align-items-center mb-4">
                <Link to="/"><FontAwesomeIcon icon={faArrowLeft} className="me-3" size="lg" /></Link>
                <h1 className="mb-0 me-3">{t('partners')}</h1>
                <PartnerCreate callback={getPartners} />
            </div>

            <div className="block shadow position-relative">
                <PartnersFilterForm callback={setFilterData} />
                <PartnersList loading={loading} partners={partners} getPartners={getPartners} />
            </div>
        </ListLayout>
    );
}

export default PartnerListPage;