import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../utils/hooks/UserProvider';
import { Form, Button, Modal, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ResetMessages, SetFormErrors } from '../../utils/misc/formControl';
import { Api } from '../../utils/services/Api';
import FormMessage from '../global/FormMessage';

function UserEditAvatarForm() {

    const { t } = useTranslation();
    const { user } = useUser();

    const [modalShow, setModalShow] = useState(false);
    const [workingUpload, setWorkingUpload] = useState(false);
    const [workingRemove, setWorkingRemove] = useState(false);
    const [data, setData] = useState({
        avatar: ''
    });
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [avatar, setAvatar] = useState(<FontAwesomeIcon icon={faUserCircle} className="text-secondary avatar" />);

    useEffect(() => {
        if (user.avatar) {
            setAvatar(<Image src={user.avatar} className="avatar" />);
        }
    },
    // eslint-disable-next-line
    [user]);

    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
    );

    const handleUplaod = async (e) => {
        e.preventDefault();

        setWorkingUpload(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, null, true);

        const formData = new FormData();
        formData.append('avatar', data.avatar);

        try {
            const res = await Api.post(`/users/${user.id}/avatar`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setWorkingUpload(false);
            setModalShow(false);
            setData({ ...data, avatar: '' });
            setAvatar(<Image src={res.data.data.avatar} className="avatar" />);
        } catch (err) {
            SetFormErrors(err, errors, setErrors, setErrorMessage);
        } finally {
            setWorkingUpload(false);
        }
    }

    const handleRemove = async () => {
        
        setWorkingRemove(true);
        try {
            await Api.delete(`/users/${user.id}/avatar`);
            setAvatar(<FontAwesomeIcon icon={faUserCircle} className="text-secondary avatar" />);
        } catch (err) {
            // Silence is golden.
        } finally {
            setWorkingRemove(false);
        }
    }

    return (
        <div>
            <h3>{t('edit')} - {user.first_name} {user.last_name}</h3>

            <Modal show={modalShow}>
                <div className="p-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="m-0">{t('uploadAvatar')}</h6>
                        <Button className="icon-btn" onClick={() => setModalShow(false)}>
                            <FontAwesomeIcon icon={faTimes} size="lg" className="text-dark" />
                        </Button>
                    </div>

                    <div className="mt-4">
                        <Form onSubmit={handleUplaod}>
                            <Form.Group className="mb-2" controlId="avatar">
                                <Form.Label>{t('avatar')}</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={(e) => setData({ ...data, avatar: e.target.files[0] })}
                                    isInvalid={!!errors.avatar}
                                    {...workingUpload ? { disabled: true } : {}}
                                />
                            </Form.Group>

                            <Button type="submit" variant="primary" className="mt-3" {...workingUpload ? {disabled: true} : {} }>
                                {workingUpload && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                {t('upload')}
                            </Button>

                            {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                        </Form>
                    </div>
                </div>
            </Modal>

            <div className="d-flex align-items-center mt-4">
                {avatar}

                <Button variant="primary" className="ms-3 btn-sm rounded me-3" onClick={() => setModalShow(true)}>
                    {t('uploadAvatar')}
                </Button>

                <Button
                    type="submit"
                    variant="secondary"
                    className="btn-sm rounded"
                    onClick={handleRemove}
                    {...workingRemove ? {disabled: true} : {} }
                >
                    {workingRemove && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                    {t('removeAvatar')}
                </Button>
            </div>
        </div>
    );
}

export default UserEditAvatarForm;