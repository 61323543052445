import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEvent } from '../../utils/hooks/EventProvider';
import { useParams } from '../../utils/hooks/ParamsProvider';
import { Form, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ResetMessages, SetFormErrors } from '../../utils/misc/formControl';
import { Api } from '../../utils/services/Api';
import { selectStyles } from '../../utils/misc/selectStyles';
import FormMessage from '../global/FormMessage';
import BlockOverlay from '../global/BlockOverlay';
import Select from 'react-select';
import EventSingleArtist from './EventSingleArtist';

function EventArtists() {

    const { t } = useTranslation();
    const { loading, event, getEvent } = useEvent();
    const { params } = useParams();

    const [modalShow, setModalShow] = useState(false);
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({
        artist: null,
        location: 'TES',
    });
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [artists, setArtists] = useState([]);
    const [artistOptions, setArtistOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);

    const getArtists = async () => {
        try {
            const res = await Api.get('/artists');
            setArtists(res.data.data.artists);
        } catch (err) {
            // Silence is golden.
        }
    }

    useEffect(() => {
        getArtists();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    useEffect(() => {
        setLocationOptions(params.locations);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params]);

    useEffect(() => {
        if (artists && artists.length > 0) {
            const options = [];
            artists.forEach((artist) => options.push({ value: artist.id, label: artist.name }));
            setArtistOptions(options);
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [artists]);

    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
    );

    const handleSubmit = async (e) => {
        e.preventDefault();

        setWorking(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, null, true);

        try {
            await Api.put(`/events/${event.id}/artist`, data);
            setData({
                artist: null,
            });
            setModalShow(false);
            getEvent();
        } catch (err) {
            SetFormErrors(err, errors, setErrors, setErrorMessage);
        } finally {
            setWorking(false);
        }
    }

    return (
        <>
            {loading ? <BlockOverlay /> :
                <div>
                    <h3>{t('artists')} - {event.name}</h3>

                    <Button variant="primary" className="me-3" onClick={() => setModalShow(true)}>
                        {t('attachArtist')}
                    </Button>

                    <Modal show={modalShow}>
                        <div className="p-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="m-0">{t('attachArtist')}</h6>
                                <Button className="icon-btn" onClick={() => setModalShow(false)}>
                                    <FontAwesomeIcon icon={faTimes} size="lg" className="text-dark" />
                                </Button>
                            </div>

                            <div className="mt-4">
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-2" controlId="artist">
                                        <Form.Label>{t('artist')}</Form.Label>
                                        <Select
                                            options={artistOptions}
                                            styles={selectStyles}
                                            value={artistOptions.find((option) => option.value === data.artist)}
                                            onChange={(option) => setData({ ...data, artist: option.value })}
                                            className={`react-select ${errors.artist ? 'is-invalid' : ''}`}
                                            placeholder={t('selectDots')}
                                            isDisabled={working}
                                        />
                                        {errors.artist && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.artist}</Form.Control.Feedback>}
                                    </Form.Group>

                                    <Form.Group className="mb-2" controlId="location">
                                        <Form.Label>{t('location')}</Form.Label>
                                        <Select
                                            options={locationOptions}
                                            styles={selectStyles}
                                            value={locationOptions && locationOptions.length > 0 ? locationOptions.find((option) => option.value === data.location) : ''}
                                            onChange={(option) => setData({ ...data, location: option.value })}
                                            className={`react-select ${errors.location ? 'is-invalid' : ''}`}
                                            placeholder={t('selectDots')}
                                            isDisabled={working}
                                        />
                                        {errors.location && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.location}</Form.Control.Feedback>}
                                    </Form.Group>

                                    <Button type="submit" variant="primary" className="mt-3" {...working ? {disabled: true} : {} }>
                                        {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                        {t('submit')}
                                    </Button>

                                    {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                                </Form>
                            </div>
                        </div>
                    </Modal>

                    <div className="mt-5">
                        {event.artists && event.artists.length > 0 ?
                            event.artists.map((artist, index) => (
                                <EventSingleArtist key={index} event={event} artist={artist} getEvent={getEvent} />
                            ))
                        
                        :
                            <div className="rounded-4 px-3 py-4 mt-4 info-box">
                                <p className="m-0">
                                    <FontAwesomeIcon icon={faInfoCircle} className="text-primary me-3" size="lg" />
                                    {t('noArtistFound')}
                                </p>
                            </div> 
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default EventArtists;