import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useEventList } from '../../utils/hooks/EventListProvider';
import { Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { SetFormErrors, ResetMessages } from '../../utils/misc/formControl';
import { Api } from '../../utils/services/Api';
import FormMessage from '../global/FormMessage';

function EventListInvite() {

    const { t } = useTranslation();
    const { loading, eventList, getEventList } = useEventList();
    const [modalShow, setModalShow] = useState(false);
    const [full, setFull] = useState(false);
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({
        email: '',
        pid: '',
        first_name: '',
        last_name: '',
    });
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]);

    useEffect(() => {
        setFull(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modalShow]);

    const handleSubmitEmail = async (e) => {
        e.preventDefault();

        setWorking(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, null, true);

        try {
            await Api.post(`/event-lists/${eventList.id}/add-user-existed`, data);
            setModalShow(false);
            setData({
                email: '',
                pid: '',
                first_name: '',
                last_name: '',
            });
            getEventList();
        } catch (err) {

            // if (err.response.status === 404) {
            //     setFull(true);
            // }

            SetFormErrors(err, errors, setErrors, setErrorMessage);
        } finally {
            setWorking(false);
        }
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        setWorking(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, null, true);

        try {
            await Api.post(`/event-lists/${eventList.id}/add-user`, data);
            getEventList();
            setModalShow(false);
            setData({
                email: '',
                pid: '',
                first_name: '',
                last_name: '',
            });
        } catch (err) {
            SetFormErrors(err, errors, setErrors, setErrorMessage);
        } finally {
            setWorking(false);
        }
    }

    return (
        <>
            {!loading &&
                <>
                    {(eventList.max_invitations !== eventList.invitations.length && eventList.event.upcoming ) &&
                        <Button variant="primary" onClick={() => setModalShow(true)}>
                            {t('invite')}
                        </Button>
                    }

                    <Modal show={modalShow}>
                        <div className="p-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="m-0">{t('invite')}</h6>
                                <Button className="icon-btn" onClick={() => setModalShow(false)}>
                                    <FontAwesomeIcon icon={faTimes} size="lg" className="text-dark" />
                                </Button>
                            </div>

                            <div className="mt-4">
                                {!full ?
                                    <Form onSubmit={handleSubmitEmail}>
                                        <Form.Group className="mb-2" controlId="email">
                                            <Form.Label>{t('email')}</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder={t('enterEmail')}
                                                value={data.email}
                                                onChange={(e) => setData({ ...data, email: e.target.value })}
                                                className={errors.email ? 'is-invalid' : ''}
                                                {...working ? { disabled: true } : {}}
                                            />
                                            {errors.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
                                        </Form.Group>

                                        <Button type="submit" variant="primary" className="mt-3" {...working ? {disabled: true} : {} }>
                                            {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                            {t('submit')}
                                        </Button>

                                        {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                                    </Form>
                                :
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group className="mb-2" controlId="email">
                                            <Form.Label>{t('email')}</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder={t('enterEmail')}
                                                value={data.email}
                                                onChange={(e) => setData({ ...data, email: e.target.value })}
                                                className={errors.email ? 'is-invalid' : ''}
                                                {...working ? { disabled: true } : {}}
                                            />
                                            {errors.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
                                        </Form.Group>

                                        <Form.Group className="mb-2" controlId="pid">
                                            <Form.Label>{t('personalNumber')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={t('personalNumber')}
                                                value={data.pid}
                                                onChange={(e) => setData({ ...data, pid: e.target.value })}
                                                className={errors.pid ? 'is-invalid' : ''}
                                                {...working ? { disabled: true } : {}}
                                            />
                                            {errors.pid && <Form.Control.Feedback type="invalid">{errors.pid}</Form.Control.Feedback>}
                                        </Form.Group>

                                        <Form.Group className="mb-2" controlId="first_name">
                                            <Form.Label>{t('firstName')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={t('firstName')}
                                                value={data.first_name}
                                                onChange={(e) => setData({ ...data, first_name: e.target.value })}
                                                className={errors.first_name ? 'is-invalid' : ''}
                                                {...working ? { disabled: true } : {}}
                                            />
                                            {errors.first_name && <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>}
                                        </Form.Group>

                                        <Form.Group className="mb-2" controlId="last_name">
                                            <Form.Label>{t('lastName')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={t('lastName')}
                                                value={data.last_name}
                                                onChange={(e) => setData({ ...data, last_name: e.target.value })}
                                                className={errors.last_name ? 'is-invalid' : ''}
                                                {...working ? { disabled: true } : {}}
                                            />
                                            {errors.last_name && <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>}
                                        </Form.Group>

                                        <Button type="submit" variant="primary" className="mt-3" {...working ? {disabled: true} : {} }>
                                            {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                            {t('submit')}
                                        </Button>

                                        {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                                    </Form>
                                }
                            </div>
                        </div>
                    </Modal>
                </>
            }
        </>  
    );
}

export default EventListInvite;