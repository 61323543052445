import { useState } from 'react';
import { useUser } from '../../utils/hooks/UserProvider';
import { useTranslation } from 'react-i18next';
import { Badge, Button } from 'react-bootstrap';
import { Api } from '../../utils/services/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../../utils/misc/formatDate';

function UserSingleBlacklist({ item }) {

    const { t } = useTranslation();
    const { getUser } = useUser();
    const [working, setWorking] = useState(false);

    const handleDelete = async () => {

        setWorking(true);
        try {
            await Api.delete(`/blacklists/${item.id}`);
            getUser();
        } catch (err) {
            // Silence is golden.
        } finally {
            setWorking(false);
        }
    }

    return (
        <div className="border rounded-4 p-3 mb-2">
            {item.active ? <Badge bg="danger">{t('active')}</Badge> : <Badge bg="dark">{t('notActive')}</Badge>}
            <p className="mt-3 mb-0">{formatDate(item.start)} - {formatDate(item.start)}</p>
            <p>{item.reason}</p>
            <Button
                variant="danger"
                size="sm"
                className="rounded"
                onClick={handleDelete}
                {...working ? {disabled: true} : {} }
            >
                {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                {t('delete')}
            </Button>
        </div>
    );
}

export default UserSingleBlacklist