import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EventProvider } from '../../utils/hooks/EventProvider';
import { useAuth } from '../../utils/hooks/AuthProvider';
import { eventMenuItems } from '../../utils/misc/eventMenuItems';
import SingleLayout from '../../components/global/SingleLayout';
import EventEditPosterForm from '../../components/event/EventEditPosterForm';
import EventEditInfoForm from '../../components/event/EventEditInfoForm';
import EventEditListPosterForm from '../../components/event/EventEditListPosterForm';

function EventInfoPage() {

    const { id } = useParams();
    const { t } = useTranslation();
    const { user } = useAuth();
    const menu = eventMenuItems(user, '/events/info', id);

    return (
        <SingleLayout title={t('event')} menuItems={menu} back="/events">
            <div className="block shadow position-relative">
                <EventProvider>
                    <EventEditPosterForm />
                    <EventEditListPosterForm />
                    <EventEditInfoForm />
                </EventProvider>
            </div>
        </SingleLayout>
    );
}

export default EventInfoPage;