import { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Api } from '../services/Api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const navigate  = useNavigate();
    const [cookies, setCookies, removeCookies] = useCookies();
    const [user, setUser] = useState({});

    const getUser = async () => {
        try {
            const res = await Api.get('/profile');
            setUser(res.data.data);
        } catch (err) {
            navigate('/login');
        }
    }

    useEffect(() => {
        if (cookies.token) {
            getUser();
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    const login = async ({email, password}) => {
        try {
            const res = await Api.post('/auth/login', {
                email: email,
                password: password
            });

            setCookies('token', res.data.data.token);
            setUser(res.data.data.user);
            navigate('/');
        } catch (err) {
            return err;
        }
    }

    const logout = async () => {
        try {
            await Api.get('/profile/logout', {
                headers: {
                    'Authorization': `Bearer ${cookies.token}`
                }
            });

            removeCookies('token');
            setUser({});
            navigate('/login');
        } catch (err) {

            removeCookies('token');
            setUser({});
            navigate('/login');
        }
    }

    const value = useMemo(
        () => ({
            cookies,
            user,
            login,
            logout
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [cookies, user]
    );

    return (
        <AuthContext.Provider value={value}>
            {cookies.token ? Object.keys(user).length > 0 && children : children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(AuthContext);
}