import { useState, useEffect, createContext, useContext, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Api } from '../services/Api';

const EventContext = createContext();

export const EventProvider = ({ children }) => {

    const navigate  = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [event, setEvent] = useState({});

    const getEvent = async () => {
        setLoading(true);

        try {
            const res = await Api.get(`/events/${id}`);
            setEvent(res.data.data);
        } catch (err) {
            navigate('/events');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getEvent();
    }
    // eslint-disable-next-line
    , []);

    const value = useMemo(
        () => ({
            loading,
            event,
            getEvent
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loading, event]
    );

    return (
        <EventContext.Provider value={value}>
            {children}
        </EventContext.Provider>
    );
}

export const useEvent = () => {
    return useContext(EventContext);
}