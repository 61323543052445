import { useTranslation } from 'react-i18next';
import { profileMenuItems } from '../../utils/misc/profileMenuItems';
import SingleLayout from '../../components/global/SingleLayout';
import ProfileQR from '../../components/profile/ProfileQR';

function ProfileQRPage() {

    const { t } = useTranslation();
    const menu = profileMenuItems('/profile/qr');

    return (
        <SingleLayout title={t('profile')} menuItems={menu}>
            <div className="block shadow position-relative">
                <ProfileQR />
            </div>
        </SingleLayout>
    );
}

export default ProfileQRPage;