import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePartner } from '../../utils/hooks/PartnerProvider';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Api } from '../../utils/services/Api';
import FormMessage from '../global/FormMessage';
import BlockOverlay from '../global/BlockOverlay';

function PartnerDelete() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { loading, partner } = usePartner();
    const [working, setWorking] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setWorking(true);
        setErrorMessage(null);

        try {
            await Api.delete(`/partners/${partner.id}`);
            navigate('/partners');
        } catch (err) {
            setErrorMessage(err.response.data.message);
        } finally {
            setWorking(false);
        }
    }

    return (
        <>
            {loading ?
                <BlockOverlay />
            :
                <div>
                    <h3>{t('delete')} - {partner.name}</h3>

                    <p className="mt-5 mb-1">
                        <FontAwesomeIcon icon={faInfoCircle} className="me-2 text-danger" size="lg" />
                        {t('deletePartnerMessage')}
                    </p>

                    <Button type="submit" variant="danger" className="mt-3" {...working ? {disabled: true} : {} } onClick={handleSubmit}>
                        {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                        {t('delete')}
                    </Button>

                    {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                </div>
            }
        </>
    );
}

export default PartnerDelete;