import { useTranslation } from 'react-i18next';
import { profileMenuItems } from '../../utils/misc/profileMenuItems';
import SingleLayout from '../../components/global/SingleLayout';
import ProfileEventLists from '../../components/profile/ProfileEventLists';

function ProfileEventListsPage() {

    const { t } = useTranslation();
    const menu = profileMenuItems('/profile/lists');

    return (
        <SingleLayout title={t('profile')} menuItems={menu}>
            <div className="block shadow position-relative">
                <ProfileEventLists />
            </div>
        </SingleLayout>
    );
}

export default ProfileEventListsPage;