import axios from 'axios';

function getHeaders() {

    const cookiesToken = document.cookie.match(`(^|;)\\s*token\\s*=\\s*([^;]+)`);
    const token = cookiesToken ? decodeURIComponent(cookiesToken[2]) : null;

    const cookiesLang = document.cookie.match(`(^|;)\\s*lang\\s*=\\s*([^;]+)`);
    const lang = cookiesLang ? decodeURIComponent(cookiesLang[2]) : 'en';

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };
  
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  
    if (lang) {
      headers['X-Language'] = lang;
    }
  
    return headers;
  }

export const Api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: false,
    headers: getHeaders()
});

Api.interceptors.request.use(config => {
    config.headers['Authorization'] = getHeaders().Authorization;
    config.headers['X-Language'] = getHeaders()['X-Language'];

    return config;
});

Api.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location.href = '/login';
    } else if (error.response.status === 403) {
        window.location.href = '/error';
    }
    return Promise.reject(error);
});