import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ArtistProvider } from '../../utils/hooks/ArtistProvider';
import { artistMenuItems } from '../../utils/misc/artistMenuItems';
import SingleLayout from '../../components/global/SingleLayout';
import ArtistDelete from '../../components/artist/ArtistDelete';

function ArtistDeletePage() {

    const { t } = useTranslation();
    const { id } = useParams();
    const menu = artistMenuItems('/artists/delete', id);

    return (
        <SingleLayout title={t('artist')} menuItems={menu} back="/artists">
            <div className="block shadow position-relative">
                <ArtistProvider>
                    <ArtistDelete />
                </ArtistProvider>
            </div>
        </SingleLayout>
    );
}

export default ArtistDeletePage;