import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Api } from '../../utils/services/Api';

function ListDelete({ id }) {

    const { t } = useTranslation();
    const [working, setWorking] = useState(false);
    const navigate = useNavigate();
    const handleDelete = async () => {
        setWorking(true);
        try {
            await Api.delete(`/lists/${id}`);
            navigate('/lists');
        } catch (err) {
            // Silence is golden.
        } finally {
            setWorking(false);
        }
    }

    return (
        <Button
            variant="danger"
            className="rounded"
            style={{width: 'fit-content'}}
            size="sm"
            {...working ? {disabled: true} : {} }
            onClick={handleDelete}
        >
            {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
            {t('delete')}
        </Button>
    );
}

export default ListDelete;