import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import ResetPasswordForm from '../../components/auth/ResetPasswordForm';
import AuthLayout from '../../components/global/AuthLayout';
import Logo from '../../assets/images/logo.png';

function ResetPasswordPage() {

    const { t } = useTranslation();
    const [success, setSuccess] = useState(false);

    const callback = () => {
        setSuccess(true);
    }

    return (
        <AuthLayout>
            {success ?
                <div className="text-center my-4">
                    <FontAwesomeIcon icon={faCheckCircle} className="text-success" size='3x' />
                    <p className="mt-3">{t('resetPasswordSuccess')}</p>
                    <Link to="/login" className="btn btn-primary mt-3">{t('login')}</Link>
                </div>
            :
                <>
                    <div className="login-logo">
                        <Image src={Logo} fluid />
                    </div>

                    <div className="mt-4">
                        <h4 className="mb-0 fw-bold">{t('login')}</h4>
                        <small className="muted">{t('signInToAccount')}</small>
                    </div>
                    
                    <ResetPasswordForm callback={callback} />

                    <div className="mt-3">
                        <small className="muted">{t('wantToLogin')} <Link to="/login" className="link-primary text-decoration-underline">{t('login')}</Link></small>
                    </div>

                    <div className="mt-3">
                        <small className="muted">{t('noAccount')} <Link to={process.env.REACT_APP_WEBSITE_URL + '#verify'} className="link-primary text-decoration-underline">{t('register')}</Link></small>
                    </div>
                </>
            }
        </AuthLayout>
    );
}

export default ResetPasswordPage;