import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useEvent } from '../../utils/hooks/EventProvider';
import { useSearchParams } from 'react-router-dom';
import { Row, Col, Table } from 'react-bootstrap';
import { Api } from '../../utils/services/Api';
import BlockOverlay from '../global/BlockOverlay';
import Pagination from '../global/Pagination';
import EventActivitySingleUser from './EventActivitySingleUser';
import PieChartComp from '../stats/PieChartComp';

function EventActivity() {

    const [searchParams] = useSearchParams();
    const { t } = useTranslation();
    const { loading, event } = useEvent();
    const [working, setWorking] = useState(true);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({});
    const [stats, setStats] = useState({});

    const getInfo = async () => {
        try {
            const res = await Api.get(`/event-activities/${event.id}`);
            setData(res.data.data.activities);
            setPagination(res.data.data.pagination);
            setStats(res.data.data.stats);
        } catch (err) {
            // Silence is golden
        } finally {
            setWorking(false);
        }
    }

    useEffect(() => {
        if (!loading) {
            if (searchParams.has('live')) {
                
                getInfo();
                const interval = setInterval(() => {
                    getInfo();
                }, 30000);

                return () => clearInterval(interval);

            } else {
                getInfo();
            }
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, ]);

    return (
        <>
            {loading || working ? 
                <BlockOverlay />
            :
                <>
                    <h2 className="mb-4">{event.name} <span className="text-danger">{`${searchParams.has('live') ? t('live') : ''}`}</span></h2>
                    <Row>
                        <Col xs={12} md={6} className="d-flex align-items-center mb-3">
                            <div className="border rounded p-2 w-100">
                                <h6>{t('genders')}</h6>
                                <PieChartComp data={stats.genders} />
                            </div>
                        </Col>

                        <Col xs={12} md={6} className="d-flex align-items-center mb-3">
                            <div className="border rounded p-2 w-100">
                                <h6>{t('ages')}</h6>
                                <PieChartComp data={stats.ages} />
                            </div>
                        </Col>

                        <Col xs={12} md={6} className="d-flex align-items-center mb-3">
                            <div className="border rounded p-2 w-100">
                                <h6>{t('listStats')}</h6>
                                <PieChartComp data={stats.lists} />
                            </div>
                        </Col>

                        <Col xs={12} md={6} className="d-flex align-items-center mb-3">
                            <div className="border rounded p-2 w-100">
                                <h6>{t('general')}</h6>
                                <Table bordered size="sm" className="mt-3">
                                    <tbody>
                                        <tr>
                                            <td>{t('sumPeople')}</td>
                                            <td>{stats.total}</td>
                                        </tr>

                                        <tr>
                                            <td>{t('sumPaid')}</td>
                                            <td>{stats.paid} ₾</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>

                    <Row className="d-none d-md-flex py-3 border-bottom bg-primary text-light rounded mb-3" style={{fontSize: '0.875rem', fontWeight: 'bold'}}>
                        <Col xs={12} md={1} className="d-flex align-items-center justify-content-start justify-content-md-center">{t('image')}</Col>
                        <Col xs={12} md={3} className="d-flex align-items-center">{t('name')}</Col>
                        <Col xs={12} md={1} className="d-flex align-items-center">{t('gender')}</Col>
                        <Col xs={12} md={1} className="d-flex align-items-center">{t('age')}</Col>
                        <Col xs={12} md={2} className="d-flex align-items-center">{t('list')}</Col>
                        <Col xs={12} md={1} className="d-flex align-items-center">{t('type')}</Col>
                        <Col xs={12} md={1} className="d-flex align-items-center">{t('amount')}</Col>
                    </Row>

                    {data.map((item, index) => (
                        <EventActivitySingleUser key={index} item={item} />
                    ))}

                    <Pagination page={page} lastPage={pagination.total_pages} setPage={setPage} />
                </>
            }
        </>
    );
}

export default EventActivity;