import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePartner } from '../../utils/hooks/PartnerProvider';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import BlockOverlay from '../global/BlockOverlay';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

function PartnerOverview() {

    const { t } = useTranslation();
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const { loading, partner } = usePartner();
    const image = partner.image ? <Image src={partner.image} className="partner-image" /> : <FontAwesomeIcon icon={faImage} className="text-secondary partner-image" />;

    return (
        <>
            {loading ?
                <BlockOverlay />
            :
                <div className="profile-overview">
                    <h3>{t('overview')} - {partner.name}</h3>

                    <div className="border rounded-4 p-3 d-flex align-items-center mt-4">
                        {partner.image ?
                            <>
                                <span onClick={() => setLightboxOpen(true)} className="d-inline-block" style={{cursor: "pointer"}}>
                                    {image}
                                </span>

                                <Lightbox
                                    open={lightboxOpen}
                                    close={() => setLightboxOpen(false)}
                                    slides={[
                                        { src: partner.image }
                                    ]}
                                    carousel={{
                                        finite: true
                                    }}
                                />
                            </>
                        :
                            image
                        }

                        <div className="ms-3">
                            <p className="fw-bold m-0">
                                {partner.name}
                            </p>

                            <p className="m-0">
                                {partner.description}
                            </p>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default PartnerOverview;