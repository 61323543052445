import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from '../../utils/services/Api';
import { Button, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ResetMessages, SetFormErrors } from '../../utils/misc/formControl';
import FormMessage from '../global/FormMessage';

function ListCreate({ callback }) {

    const { t } = useTranslation();
    const [modalShow, setModalShow] = useState(false);
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({
        name: '',
        description: '',
    });
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [data]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setWorking(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, null, true);

        try {
            await Api.post('/lists', data);
            setModalShow(false);
            setData({
                name: '',
                description: '',
            });
            callback();
        } catch (err) {
            SetFormErrors(err, errors, setErrors, setErrorMessage);
        } finally {
            setWorking(false);
        }
    }

    return (
        <>
            <Button variant="primary" onClick={() => setModalShow(true)}>
                {t('newList')}
            </Button>

            <Modal show={modalShow}>
                <div className="p-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="m-0">{t('createList')}</h6>
                        <Button className="icon-btn" onClick={() => setModalShow(false)}>
                            <FontAwesomeIcon icon={faTimes} size="lg" className="text-dark" />
                        </Button>
                    </div>

                    <div className="mt-4">
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-2" controlId="name">
                                <Form.Label>{t('name')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={data.name || ''}
                                    onChange={(e) => setData({ ...data, name: e.target.value })}
                                    className={errors.name ? 'is-invalid' : ''}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.name && <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="description">
                                <Form.Label>{t('description')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={data.description || ''}
                                    onChange={(e) => setData({ ...data, description: e.target.value })}
                                    className={errors.description ? 'is-invalid' : ''}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.description && <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>}
                            </Form.Group>

                            <Button type="submit" variant="primary" className="mt-3" {...working ? {disabled: true} : {} }>
                                {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                {t('submit')}
                            </Button>

                            {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default ListCreate;