import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Api } from '../../utils/services/Api';

function EventSingleArtist({ event, artist, getEvent }) {

    const { t } = useTranslation();
    const [working, setWorking] = useState(false);

    const handleDetete = async () => {

        setWorking(true);

        try {
            await Api.delete(`/events/${event.id}/artist/${artist.id}`);
            getEvent();
        } catch (err) {
            // Silence is golden.
        }
    }

    return (
        <div className="border rounded-4 p-3 mb-2 d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
                {artist.image ? <Image src={artist.image} className="artist-image" /> : <FontAwesomeIcon icon={faUserCircle} className="text-secondary artist-image" />}
                <div>
                    <Badge variant="secondary">{artist.type_name}</Badge>
                    <Badge className="bg-secondary ms-3">{artist.location}</Badge>
                    <h6 className="mb-0 mt-2">{artist.name}</h6>
                </div>
            </div>

            <Button
                variant="danger"
                className="btn-sm rounded"
                onClick={handleDetete}
                {...working ? {disabled: true} : {} }
            >
                {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                {t('detach')}
            </Button>
        </div>
    );
}

export default EventSingleArtist;