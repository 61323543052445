import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ResetMessages, SetFormErrors } from '../../utils/misc/formControl';
import { AxiosError } from 'axios';
import { useAuth } from '../../utils/hooks/AuthProvider';
import FormMessage from '../global/FormMessage';

function LoginForm() {

    const { t } = useTranslation();
    const { login } = useAuth();
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({
        email: '',
        password: ''
    });
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
    );

    const handleSubmit = async (e) => {
        e.preventDefault();

        setWorking(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);

        const auth = await login(data);
        if (auth instanceof AxiosError) {
            SetFormErrors(auth, errors, setErrors, setErrorMessage);
        }

        setWorking(false);
    }

    return (
        <Form onSubmit={handleSubmit} className="mt-3">
            <Form.Group controlId="email" className="mb-2">
                <Form.Label>{t('emailAddress')}</Form.Label>
                <Form.Control
                    type="text"
                    placeholder={t('enterEmail')}
                    value={data.email}
                    onChange={(e) => setData({ ...data, email: e.target.value })}
                    isInvalid={!!errors.email}
                    {...working ? {disabled: true} : {}}
                />
                {errors.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group controlId="password" className="mb-2">
                <Form.Label>{t('password')}</Form.Label>
                <Form.Control
                    type="password"
                    placeholder={t('enterPassword')}
                    value={data.password}
                    onChange={(e) => setData({ ...data, password: e.target.value })}
                    isInvalid={!!errors.password}
                    {...working ? {disabled: true} : {}}
                />
                {errors.password && <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>}
            </Form.Group>

            <Button type="submit" variant="primary" className="mt-2" {...working ? {disabled: true} : {} }>
                {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                {t('login')}
            </Button>

            {errorMessage && <FormMessage type="danger" message={errorMessage} />}
        </Form>
    );
}

export default LoginForm;