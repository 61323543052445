import { useTranslation } from 'react-i18next';
import { useUser } from '../../utils/hooks/UserProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import BlockOverlay from '../global/BlockOverlay';
import UserSingleList from './UserSingleList';

function UserLists() {

    const { t } = useTranslation();
    const { loading, user } = useUser();

    return (
        <>
            {loading ? <BlockOverlay /> :
                <div>
                    <h3>{t('lists')} - {user.first_name} {user.last_name}</h3>

                    <div className="mt-5">
                        {user.user_lists && user.user_lists.length > 0 ?
                            <>
                                {user.user_lists.map((item, index) => (
                                    <UserSingleList key={index} item={item} />
                                ))}
                            </>
                        :
                            <div className="rounded-4 px-3 py-4 mt-4 info-box">
                                <p className="m-0">
                                    <FontAwesomeIcon icon={faInfoCircle} className="text-primary me-3" size="lg" />
                                    {t('noListsFound')}
                                </p>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default UserLists;