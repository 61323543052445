import { useTranslation } from 'react-i18next';
import { useAuth } from '../../utils/hooks/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faImage, faInfoCircle, faKey } from '@fortawesome/free-solid-svg-icons';
import { Col, Image, Row } from 'react-bootstrap';
import { formatDateTime } from '../../utils/misc/formatDate';
import BlockOverlay from '../global/BlockOverlay';

function ProfileInvitations() {

    const { t } = useTranslation();
    const { loading, user } = useAuth();

    return (
        <>
            {loading ? <BlockOverlay /> : 
                <div className="profile-overview">
                    <h3>{t('invitations')}</h3>
                    <div className="mt-4">
                        {user.user_invitations && user.user_invitations.length > 0 ?
                            <>
                                {user.user_invitations.map((item, index) => (
                                    <div key={index} className="border rounded-4 p-3 mb-2">
                                        <Row>
                                            <Col xs={12} md={4} className="d-flex align-items-center py-1">
                                                {item.event.poster ? <Image src={item.event.poster} className="header-avatar" /> : <FontAwesomeIcon icon={faImage} className="text-secondary header-avatar" />}
                                                <div className="ms-3">
                                                    <p className="fw-bold m-0">
                                                        {item.event.name}
                                                    </p>

                                                    <p className="fw-bold m-0">
                                                        <FontAwesomeIcon icon={faCalendar} className="me-2" />
                                                        <small>{formatDateTime(item.event.start)}</small>
                                                    </p>
                                                </div>
                                            </Col>

                                            <Col xs={12} md={4} className="d-flex align-items-center mb-2 mb-md-0">
                                                <div>
                                                    <p className="fw-bold m-0">
                                                        <FontAwesomeIcon icon={faKey} className="me-2 text-warning" />
                                                        <small>{item.event_list.type_name}</small>
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                            </>
                        :
                            <div className="rounded-4 px-3 py-4 mt-4 info-box">
                                <p className="m-0">
                                    <FontAwesomeIcon icon={faInfoCircle} className="text-primary me-3" size="lg" />
                                    {t('noInvitationsFound')}
                                </p>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default ProfileInvitations;