import { useState, useEffect, createContext, useContext, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Api } from '../services/Api';

const ListContext = createContext();

export const ListProvider = ({ children }) => {

    const navigate  = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState({});

    const getList = async () => {
        setLoading(true);

        try {
            const res = await Api.get(`/lists/${id}`);
            setList(res.data.data);
        } catch (err) {
            navigate('/lists');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getList();
    }
    // eslint-disable-next-line
    , []);

    const value = useMemo(
        () => ({
            loading,
            list,
            getList
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loading, list]
    );

    return (
        <ListContext.Provider value={value}>
            {children}
        </ListContext.Provider>
    );
}

export const useList = () => {
    return useContext(ListContext);
}