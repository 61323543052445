import { useTranslation } from 'react-i18next';
import { EventListProvider } from '../../utils/hooks/EventListProvider';
import { useAuth } from '../../utils/hooks/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import ListLayout from '../../components/global/ListLayout';
import EventListDetails from '../../components/eventlist/EventListDetails';
import EventListInvitations from '../../components/eventlist/EventListInvitations';
import EventListInvite from '../../components/eventlist/EventListInvite';
import EventListInviteBulk from '../../components/eventlist/EventListInviteBulk';

function EventListPage() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { loading, user } = useAuth();

    return (
        <ListLayout>
            <EventListProvider>
                <div className="d-flex align-items-center mb-4">
                    <Button className="icon-btn" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} className="me-3 text-dark" size="lg" /></Button>
                    <h1 className="mb-0 me-3">{t('invitations')}</h1>
                    <EventListInvite />
                    {!loading && user.role === 'administrator' && <EventListInviteBulk />}
                </div>

                <div className="block shadow position-relative">
                    <EventListDetails />
                    <EventListInvitations />
                </div>
            </EventListProvider>
        </ListLayout>
    );
}

export default EventListPage;