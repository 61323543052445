import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { partnerMenuItems } from '../../utils/misc/partnerMenuItems';
import { PartnerProvider } from '../../utils/hooks/PartnerProvider';
import { Api } from '../../utils/services/Api';
import SingleLayout from '../../components/global/SingleLayout';
import PartnerEventsFilterForm from '../../components/partner/PartnerEventsFilterForm';
import PartnerEventsList from '../../components/partner/PartnerEventsList';
import Pagination from '../../components/global/Pagination';

function PartnerEventsPage() {

    const { id } = useParams();
    const { t } = useTranslation();
    const menu = partnerMenuItems('/partners/events', id);
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({});

    const getEvents = async (data) => {
        setLoading(true);
        try {
            const res = await Api.get('/events', {
                params: {
                    page: page,
                    partner: id,
                    ...data
                }
            });
            setEvents(res.data.data.events);
            setPagination(res.data.data.pagination);
        } catch (err) {
            setEvents([]);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        setPage(1);
        getEvents();
    },
    // eslint-disable-next-line
    []);

    useEffect(() => {
        getEvents();
    }
    // eslint-disable-next-line
    , [page]);

    return (
        <SingleLayout title={t('partner')} menuItems={menu} back="/partners">
            <div className="block shadow position-relative">
                <PartnerProvider>
                    <PartnerEventsFilterForm callback={getEvents} />
                    <PartnerEventsList loading={loading} events={events} />
                    <Pagination page={page} lastPage={pagination.total_pages} setPage={setPage} />
                </PartnerProvider>
            </div>
        </SingleLayout>
    );
}

export default PartnerEventsPage;