import i18n from '../../i18n';

export const profileMenuItems = (active) => {

    let items = [
        {
            title: i18n.t('overview'),
            path: '/profile',
            active: false
        },
        {
            title: i18n.t('qrCode'),
            path: '/profile/qr',
            active: false
        },
        {
            title: i18n.t('lists'),
            path: '/profile/lists',
            active: false
        },
        {
            title: i18n.t('invitations'),
            path: '/profile/invitations',
            active: false
        },
        {
            title: i18n.t('blacklists'),
            path: '/profile/blacklists',
            active: false
        },
        {
            title: i18n.t('changePassword'),
            path: '/profile/change-password',
            active: false
        }
    ];

    items.forEach((item) => {
        if (item.path === active) {
            item.active = true;
        }
    });

    return items;
}