import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useEvent } from '../../utils/hooks/EventProvider';
import { useParams } from '../../utils/hooks/ParamsProvider';
import { Api } from '../../utils/services/Api';
import { selectStyles } from '../../utils/misc/selectStyles';
import { SetFormErrors, ResetMessages } from '../../utils/misc/formControl';
import { Modal, Row, Col, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faUsers, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import FormMessage from '../global/FormMessage';
import Select from 'react-select';
import EventListDelete from './EventListDelete';

function EventListSingle({ list }) {

    const { t } = useTranslation();
    const { getEvent } = useEvent();
    const { params } = useParams();
    const [modalShow, setModalShow] = useState(false);
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({
        user: list.user ? list.user.email : '',
        name: list.name,
        max_invitations: list.max_invitations,
        type: list.type,
    });
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);

    const eventTypeOptions = params.event_list_types || [];

    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setWorking(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, null, true);

        try {
            await Api.put('/event-lists/' + list.id, data);
            setModalShow(false);
            getEvent();
        } catch (err) {
            SetFormErrors(err, errors, setErrors, setErrorMessage);
        } finally {
            setWorking(false);
        }
    }

    return (
        <div className="border rounded-4 p-3 mb-2">
            <Modal show={modalShow}>
                <div className="p-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="m-0">{t('editEventList')}</h6>
                        <Button className="icon-btn" onClick={() => setModalShow(false)}>
                            <FontAwesomeIcon icon={faTimes} size="lg" className="text-dark" />
                        </Button>
                    </div>

                    <div className="mt-4">
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-2" controlId="user">
                                <Form.Label>{t('user')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('enterEmail')}
                                    value={data.user || ''}
                                    onChange={(e) => setData({ ...data, user: e.target.value })}
                                    className={errors.user ? 'is-invalid' : ''}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.user && <Form.Control.Feedback type="invalid">{errors.user}</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="name">
                                <Form.Label>{t('name')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('name')}
                                    value={data.name || ''}
                                    onChange={(e) => setData({ ...data, name: e.target.value })}
                                    className={errors.name ? 'is-invalid' : ''}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.name && <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="max_invitations">
                                <Form.Label>{t('maxInvitations')}</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder={t('maxInvitations')}
                                    value={data.max_invitations || ''}
                                    onChange={(e) => setData({ ...data, max_invitations: e.target.value })}
                                    className={errors.max_invitations ? 'is-invalid' : ''}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.max_invitations && <Form.Control.Feedback type="invalid">{errors.max_invitations}</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="type">
                                <Form.Label>{t('type')}</Form.Label>
                                <Select
                                    value={eventTypeOptions.find((option) => option.value === data.type)}
                                    options={eventTypeOptions}
                                    styles={selectStyles}
                                    placeholder={t('selectDots')}
                                    className={`react-select ${errors.gender ? 'is-invalid' : ''}`}
                                    onChange={(option) => setData({ ...data, type: option.value })}
                                    isDisabled={working}
                                />
                                {errors.type && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.type}</Form.Control.Feedback>}
                            </Form.Group>

                            <Button type="submit" variant="primary" className="mt-3" {...working ? {disabled: true} : {} }>
                                {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                {t('submit')}
                            </Button>

                            {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                        </Form>
                    </div>
                </div>
            </Modal>

            <Row>
                <Col xs={12} md={3} className="d-flex align-items-center mb-2 mb-md-0">
                    <h6>{list.name}</h6>
                </Col>

                <Col xs={12} md={2} className="d-flex align-items-center mb-2 mb-md-0">
                    <FontAwesomeIcon icon={faUsers} className="me-2" />
                    {list.max_invitations}
                </Col>

                <Col xs={12} md={3} className="d-flex align-items-center mb-2 mb-md-0">
                    <FontAwesomeIcon icon={faKey} className="me-2" />
                    <small>{list.type_name}</small>
                </Col>

                <Col xs={12} md={4} className="d-flex align-items-center justify-content-start justify-content-md-end mb-2 mb-md-0">
                    <Button
                        variant="warning"
                        className="btn-sm rounded me-2"
                        onClick={() => setModalShow(true)}
                    >
                        {t('edit')}
                    </Button>

                    <Link
                        to={`/event-lists/${list.id}`}
                        className="btn btn-sm btn-primary rounded me-2"
                    >
                        {t('view')}
                    </Link>

                    <EventListDelete list={list} />
                </Col>
            </Row>
        </div>
    );
}

export default EventListSingle;