import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useEvent } from '../../utils/hooks/EventProvider';
import { useParams } from '../../utils/hooks/ParamsProvider';
import { Modal, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { SetFormErrors, ResetMessages } from '../../utils/misc/formControl';
import { Api } from '../../utils/services/Api';
import { selectStyles } from '../../utils/misc/selectStyles';
import FormMessage from '../global/FormMessage';
import Select from 'react-select';

function EventListCreate() {

    const { t } = useTranslation();
    const { event, getEvent } = useEvent();
    const { params } = useParams();
    const [modalShow, setModalShow] = useState(false);
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({
        user: '',
        name: '',
        max_invitations: '',
        type: '',
    });
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const eventTypeOptions = params.event_list_types || [];

    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setWorking(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, null, true);

        try {
            await Api.post('/event-lists/' + event.id, data);
            getEvent();
        } catch (err) {
            SetFormErrors(err, errors, setErrors, setErrorMessage);
        } finally {
            setWorking(false);
        }
    }

    return (
        <>
            <Button variant="primary" onClick={() => setModalShow(true)} className="me-3">
                {t('newEventList')}
            </Button>

            <Modal show={modalShow}>
                <div className="p-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="m-0">{t('createEventList')}</h6>
                        <Button className="icon-btn" onClick={() => setModalShow(false)}>
                            <FontAwesomeIcon icon={faTimes} size="lg" className="text-dark" />
                        </Button>
                    </div>

                    <div className="mt-4">
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-2" controlId="user">
                                <Form.Label>{t('user')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('enterEmail')}
                                    value={data.user || ''}
                                    onChange={(e) => setData({ ...data, user: e.target.value })}
                                    className={errors.user ? 'is-invalid' : ''}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.user && <Form.Control.Feedback type="invalid">{errors.user}</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="name">
                                <Form.Label>{t('name')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('name')}
                                    value={data.name || ''}
                                    onChange={(e) => setData({ ...data, name: e.target.value })}
                                    className={errors.name ? 'is-invalid' : ''}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.name && <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="max_invitations">
                                <Form.Label>{t('maxInvitations')}</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder={t('maxInvitations')}
                                    value={data.max_invitations || ''}
                                    onChange={(e) => setData({ ...data, max_invitations: e.target.value })}
                                    className={errors.max_invitations ? 'is-invalid' : ''}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.max_invitations && <Form.Control.Feedback type="invalid">{errors.max_invitations}</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="type">
                                <Form.Label>{t('type')}</Form.Label>
                                <Select
                                    value={eventTypeOptions.find((option) => option.value === data.type)}
                                    options={eventTypeOptions}
                                    styles={selectStyles}
                                    placeholder={t('selectDots')}
                                    className={`react-select ${errors.type ? 'is-invalid' : ''}`}
                                    onChange={(option) => setData({ ...data, type: option.value })}
                                    isDisabled={working}
                                />
                                {errors.type && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.type}</Form.Control.Feedback>}
                            </Form.Group>

                            <Button type="submit" variant="primary" className="mt-3" {...working ? {disabled: true} : {} }>
                                {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                {t('submit')}
                            </Button>

                            {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default EventListCreate;