import i18n from '../../i18n';

export const userMenuItems = (active, id) => {

    let items = [
        {
            title: i18n.t('overview'),
            path: '/users/' + id,
            active: false
        },
        {
            title: i18n.t('qrCode'),
            path: '/users/qr/' + id,
            active: false
        },
        {
            title: i18n.t('userInfo'),
            path: '/users/info/' + id,
            active: false
        },
        {
            title: i18n.t('roleStatus'),
            path: '/users/role-status/' + id,
            active: false
        },
        {
            title: i18n.t('artist'),
            path: '/users/artist/' + id,
            active: false
        },
        {
            title: i18n.t('lists'),
            path: '/users/lists/' + id,
            active: false
        },
        {
            title: i18n.t('blacklists'),
            path: '/users/blacklists/' + id,
            active: false
        },
        {
            title: i18n.t('logs'),
            path: '/users/logs/' + id,
            active: false
        },
        {
            title: i18n.t('delete'),
            path: '/users/delete/' + id,
            active: false
        }
    ];

    items.forEach((item) => {
        if (active + '/' + id === item.path) {
            item.active = true;
        }
    });

    return items;
}