import i18n from '../../i18n';

export const eventMenuItems = (user, active, id) => {

    const role = user.role ? user.role : null;
    if (!role) {
        return [];
    }

    let items = [
        {
            title: i18n.t('overview'),
            path: '/events/' + id,
            active: false
        },
        {
            title: i18n.t('eventInfo'),
            path: '/events/info/' + id,
            active: false
        },
        {
            title: i18n.t('artists'),
            path: '/events/artists/' + id,
            active: false
        },
    ];

    if (role === 'administrator') {

        items.push({
            title: i18n.t('lists'),
            path: '/events/lists/' + id,
            active: false
        });

        items.push({
            title: i18n.t('partner'),
            path: '/events/partner/' + id,
            active: false
        });

        items.push({
            title: i18n.t('stats'),
            path: '/stats/' + id,
            active: false
        });

        items.push({
            title: i18n.t('delete'),
            path: '/events/delete/' + id,
            active: false
        });
    }

    items.forEach((item) => {
        if (active + '/' + id === item.path) {
            item.active = true;
        }
    });

    return items;
}