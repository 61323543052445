import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from '../../utils/services/Api';
import ListLayout from '../../components/global/ListLayout';
import UsersFilterForm from '../../components/user/UsersFilterForm';
import UsersList from '../../components/user/UsersList';
import UsersMobileFilter from '../../components/user/UsersMobileFilter';
import UsersBulkAction from '../../components/user/UsersBulkAction';
import Pagination from '../../components/global/Pagination';
import UserCreate from '../../components/user/UserCreate';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import UsersBulkUpload from '../../components/user/UsersBulkUpload';

function UsersListPage() {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [page, setPage] = useState(1);
    const [users, setUsers] = useState([]);
    const [pagination, setPagination] = useState({});
    const [checkedUsers, setCheckedUsers] = useState([]);

    const setFilterData = (data) => {
        setData(data);
    }

    const getUsers = async () => {
        setLoading(true);
        try {
            const res = await Api.get('/users', {
                params: {
                    page: page,
                    ...data
                }
            });
            setUsers(res.data.data.users);
            setPagination(res.data.data.pagination);
            setLoading(false);
        } catch (err) {
            setUsers([]);
        }
    }

    useEffect(() => {
        setPage(1);
        getUsers();
    },
    // eslint-disable-next-line
    [data]);

    useEffect(() => {
        getUsers();
    }
    // eslint-disable-next-line
    , [page]);


    return (
        <ListLayout>
            <div className="d-flex align-items-center mb-4">
                <Link to="/"><FontAwesomeIcon icon={faArrowLeft} className="me-3" size="lg" /></Link>
                <h1 className="mb-0 me-3">{t('users')}</h1>
                <UserCreate />
                <UsersBulkUpload callback={setFilterData} />
            </div>

            <div className="block shadow position-relative">
                <div className="d-none d-md-block">
                    <UsersFilterForm callback={setFilterData} initial={data} />
                </div>

                <div className="d-md-none">
                    <UsersMobileFilter callback={setFilterData} initial={data} />
                </div>

                <div className="mb-3">
                    <UsersBulkAction users={checkedUsers} setCheckedUsers={setCheckedUsers} callback={getUsers} />
                </div>

                <UsersList loading={loading} users={users} setCheckedUsers={setCheckedUsers} />

                <Pagination page={page} lastPage={pagination.total_pages} setPage={setPage} />
            </div>
        </ListLayout>
    );
}

export default UsersListPage;