import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Api } from '../../utils/services/Api';
import FormMessage from '../global/FormMessage';

function UsersBulkUpload({ callback }) {

    const { t } = useTranslation();
    const [modalShow, setModalShow] = useState(false);
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({
        xls: ''
    });
    const [errorMessage, setErrorMessage] = useState(null);
    const [imported, setImported] = useState(null);

    const closeModal = () => {
        setModalShow(false);
        setImported(null);
    }

    const handleUplaod = async (e) => {
        e.preventDefault();

        setWorking(true);
        try {
            const formData = new FormData();
            formData.append('xls', data.xls);

            const res = await Api.post(`/users/bulk`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setImported(res.data.data.imported);
            setData({ ...data, xls: '' });
            callback({
                email: '',
                pid: '',
                name: '',
                role: '',
                status: '',
                list: ''
            });
        } catch (err) {
            setErrorMessage(err.response.data.message);
        } finally {
            setWorking(false);
        }
    }

    return (
        <>
            <Button variant="dark" className="ms-3 rounded me-3" onClick={() => setModalShow(true)}>
                {t('bulkUpload')}
            </Button>

            <Modal show={modalShow}>
                <div className="p-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="m-0">{t('usersBulkUplaod')}</h6>
                        <Button className="icon-btn" onClick={() => closeModal(false)}>
                            <FontAwesomeIcon icon={faTimes} size="lg" className="text-dark" />
                        </Button>
                    </div>

                    <div className="mt-4">
                        {!imported ?
                            <Form onSubmit={handleUplaod}>
                                <Form.Group className="mb-2" controlId="xls">
                                    <Form.Label>{t('xlsFile')}</Form.Label>
                                    <Form.Control
                                        type="file"
                                        onChange={(e) => setData({ ...data, xls: e.target.files[0] })}
                                        {...working ? { disabled: true } : {}}
                                    />
                                </Form.Group>

                                <Button type="submit" variant="primary" className="mt-3" {...working ? {disabled: true} : {} }>
                                    {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                    {t('upload')}
                                </Button>

                                {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                            </Form>
                        :
                            <div className="text-center my-4">
                                <FontAwesomeIcon icon={faCheckCircle} className="text-success" size='3x' />
                                <h5 className="mt-3">{t('importSuccess')}</h5>
                                <p>{t('imported')} {imported} {t('users')}</p>
                                <Button variant="primary" className="mt-3" onClick={() => closeModal(false)}>
                                    {t('close')}
                                </Button>
                            </div>
                        }
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default UsersBulkUpload;