import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Row, Col, Badge } from 'react-bootstrap';
import BlockOverlay from '../global/BlockOverlay';

function ListsList({ loading, lists, getLists }) {

    const { t } = useTranslation();
    return (
        <>
            {loading ? 
                <BlockOverlay />
            :
                <>
                    <Row>
                        {lists.map((list, index) => (
                            <Col key={index} xs={12} md={6} lg={4} xl={3} className="mb-4">
                                <div className="border rounded-4 p-3 mb-2 h-100 d-flex flex-column justify-content-between list-item">
                                    <Link to={`/lists/${list.id}`}>
                                        <Badge variant="secondary">{list.users_count} {t('users')}</Badge>
                                        <h6 className="mb-0 mt-2">{list.name}</h6>
                                        <p>{list.description}</p>
                                    </Link>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </>   
            }
        </>
    );
}

export default ListsList