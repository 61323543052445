import { useTranslation } from 'react-i18next';
import { useList } from '../../utils/hooks/ListProvider';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BlockOverlay from '../global/BlockOverlay';
import ListDelete from './ListDelete';
import ListEdit from './ListEdit';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ListOverview() {

    const { t } = useTranslation();
    const { loading, list } = useList();

    return (
        <>
            {loading ? <BlockOverlay /> :
                <div className="profile-overview">
                    <div className="d-flex align-items-center mb-4">
                        <Link to="/lists"><FontAwesomeIcon icon={faArrowLeft} className="me-3" size="lg" /></Link>
                        <h3 className="m-0 p-0">{t('list')} - {list.name}</h3>
                    </div>
        
                    <div className="border rounded-4 p-3 mt-4">
                        <Row>
                            <Col xs={12} md={1} className="mb-2">
                                <small className='fw-bold'>{t('id')}</small>
                                <p className='p-0 m-0'>{list.id}</p>
                            </Col>

                            <Col xs={12} md={3} className="mb-2">
                                <small className='fw-bold'>{t('name')}</small>
                                <p className='p-0 m-0'>{list.name}</p>
                            </Col>

                            <Col xs={12} md={6} className="mb-2">
                                <small className='fw-bold'>{t('description')}</small>
                                <p className='p-0 m-0'>{list.description}</p>
                            </Col>

                            <Col xs={12} md={2} className="mb-2 d-flex align-items-center">
                                <ListEdit />
                                <ListDelete id={list.id} />
                            </Col>
                        </Row>
                    </div>
                </div>
            }
        </>
    );
}

export default ListOverview