import { useTranslation } from 'react-i18next';
import { profileMenuItems } from '../../utils/misc/profileMenuItems';
import SingleLayout from '../../components/global/SingleLayout';
import ProfilePassword from '../../components/profile/ProfilePassword';

function ProfilePasswordPage() {

    const { t } = useTranslation();
    const menu = profileMenuItems('/profile/change-password');

    return (
        <SingleLayout title={t('profile')} menuItems={menu}>
            <div className="block shadow position-relative">
                <ProfilePassword />
            </div>
        </SingleLayout>
    );
}

export default ProfilePasswordPage;